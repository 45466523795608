import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ErrorService } from './error.service';
import { LoggingService } from '../logger/logging.service';
import { NotificationService } from '@core/notifications/notification.service';
import { TranslocoService } from '@ngneat/transloco';
// import { NotificationService } from '../notifications/notification.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  public message: string;
  public stackTrace: any;

  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);
    const translateService = this.injector.get(TranslocoService);

    // let message: string;
    // let stackTrace: any;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      this.message = errorService.getServerMessage(error);
      // this.stackTrace = errorService.getServerStack(error);
      if (error.status === 404) {
        //notifier.showError(
        //translateService.translate<string>('masyaf.login-error')
        //);
      } else if (error.status !== 422) {
        // 422: vlaid membership but invalid phone
        //notifier.showError(this.message);
      }
      // Always log errors
      logger.logError(this.message, this.stackTrace, error);
    } else if (error instanceof Error) {
      // Client Error
      this.message = errorService.getClientMessage(error);
      this.stackTrace = errorService.getClientStack(error);
      // notifier.showError(this.message);
      logger.logError(this.message, this.stackTrace, error);
    }
  }
}
