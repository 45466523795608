import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { AmountBreakdown } from 'src/app/shared/interfaces/amount-breakdown';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TotalBreakdownService } from '@community/shared/total-breakdown.service';
import { Promocode, PromocodeResult } from '@shared/interfaces/promocode';
import { XpayService } from '@community/shared/xpay.service';
import { AuthService } from '@core/auth/auth.service';
import { PayService } from '@pay/pay/pay.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, concat } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-total-breakdown',
  templateUrl: './total-breakdown.component.html',
  styleUrls: ['./total-breakdown.component.scss']
})
export class TotalBreakdownComponent implements OnInit, OnDestroy {
  totalBreakdown: AmountBreakdown;
  modalRef: BsModalRef;
  promocode: Promocode = {} as Promocode;
  promocodeResult: PromocodeResult;
  formSubmitted: boolean;
  promocodeError: boolean;
  form: FormGroup;
  formError: boolean;
  ngUnsubscribe = new Subject();
  isUserLoggedIn: boolean;
  @Input() paymentFor: string;
  @Input() ids: string[];
  @Input() activatePromocode = true;
  @Input() showCalculationMayDifferWarning: boolean;
  @Input() allowPpromoCode: boolean;
  @Input() selectedTicketsInfo: {
    id: number;
    ticket_price: number;
    count: number;
    ticket_total_amount: number;
  }[];

  constructor(
    private modalService: BsModalService,
    private breakdownService: TotalBreakdownService,
    private communityService: XpayService,
    private authService: AuthService,
    private payService: PayService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    console.log('breakdown component payment for: ', this.paymentFor);
    console.log('breakdown component ids: ', this.ids);
    console.log('breakdown selectedTicketsInfo: ', this.selectedTicketsInfo);
    this.breakdownService
      .getTotalBreakdown()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(el => {
        this.totalBreakdown = el;
        this.promocodeResult = null;
        this.payService.changePromocodeResult(null);
        this.initPromocodeInfo();
      });
    this.isUserLoggedIn = this.authService.currentUserValue ? true : false;
    this.initPromocodeForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Detect changes in selectedTicketsInfo
    if (changes.selectedTicketsInfo && this.selectedTicketsInfo) {
      console.log('selectedTicketsInfo changed:', this.selectedTicketsInfo);
      this.initPromocodeInfo(); // Reinitialize promo code info when input changes
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initPromocodeForm() {
    this.form = this.formBuilder.group({
      promocode: ['', Validators.required]
    });

    if (!this.isUserLoggedIn) {
      this.addPhoneNumberControl();
    }
  }

  addPhoneNumberControl() {
    this.form.addControl(
      'phone_number',
      this.formBuilder.control('', Validators.required)
    );
  }

  get promocodeControl() {
    return this.form.controls.promocode;
  }

  get phoneNumberControl() {
    return this.form.controls.phone_number;
  }

  initPromocodeInfo() {
    this.promocode.payment_for = this.paymentFor.toUpperCase();
    this.promocode.payment_object_ids = this.ids;
    this.promocode.tickets_objects = this.selectedTicketsInfo;
    this.promocode.community_id = this.communityService.communityId;
    this.breakdownService.promocode = this.promocode;
  }

  showPromocodeForm(template: TemplateRef<any>) {
    this.form.reset();
    this.modalRef = this.modalService.show(template);
  }

  async checkPromocode(promocode: string) {
    this.formSubmitted = true;
    this.promocodeError = true;

    if (this.form.valid) {
      this.promocode.name = promocode;
      this.promocode.amount = this.totalBreakdown.grand_total_amount;
      this.promocode.currency = this.breakdownService.currentPaymentCurrency;
      this.promocode.phone_number = this.isUserLoggedIn
        ? this.authService.currentUserValue.user.username
        : this.phoneNumberControl.value.internationalNumber.replace(/\s+/g, '');

      const cardPromo$ = this.breakdownService.checkPromoCode(this.promocode);
      const observables = [cardPromo$];

      // Define the type for indexMapping
      const indexMapping: {
        card?: number;
        cash?: number;
        kiosk?: number;
        fawry?: number;
        valu?: number;
        upg?: number;
        installment?: number;
      } = {
        card: 0
      };

      let currentIndex = 1;

      if (
        this.totalBreakdown.cash &&
        this.totalBreakdown.cash.grand_total_amount > 0
      ) {
        const cashPayload = { ...this.promocode };
        cashPayload.amount = this.totalBreakdown.cash.grand_total_amount;
        const cashPromo$ = this.breakdownService.checkPromoCode(cashPayload);
        observables.push(cashPromo$);
        indexMapping.cash = currentIndex++;
      }

      if (
        this.totalBreakdown.kiosk &&
        this.totalBreakdown.kiosk.grand_total_amount > 0
      ) {
        const kioskPayload = { ...this.promocode };
        kioskPayload.amount = this.totalBreakdown.kiosk.grand_total_amount;
        const kioskPromo$ = this.breakdownService.checkPromoCode(kioskPayload);
        observables.push(kioskPromo$);
        indexMapping.kiosk = currentIndex++;
      }

      if (
        this.totalBreakdown.fawry &&
        this.totalBreakdown.fawry.grand_total_amount > 0
      ) {
        const fawryPayload = { ...this.promocode };
        fawryPayload.amount = this.totalBreakdown.fawry.grand_total_amount;
        const fawryPromo$ = this.breakdownService.checkPromoCode(fawryPayload);
        observables.push(fawryPromo$);
        indexMapping.fawry = currentIndex++;
      }

      if (
        this.totalBreakdown.valu &&
        this.totalBreakdown.valu.grand_total_amount > 0
      ) {
        const valuPayload = { ...this.promocode };
        valuPayload.amount = this.totalBreakdown.valu.grand_total_amount;
        const valuPromo$ = this.breakdownService.checkPromoCode(valuPayload);
        observables.push(valuPromo$);
        indexMapping.valu = currentIndex++;
      }

      if (
        this.totalBreakdown['meeza/digital'] &&
        this.totalBreakdown['meeza/digital'].grand_total_amount > 0
      ) {
        const meezaDigitalPayload = { ...this.promocode };
        meezaDigitalPayload.amount = this.totalBreakdown[
          'meeza/digital'
        ].grand_total_amount;
        const meezaDigitalPromo$ = this.breakdownService.checkPromoCode(
          meezaDigitalPayload
        );
        observables.push(meezaDigitalPromo$);
        indexMapping.upg = currentIndex++;
      }
      
      if (this.totalBreakdown.installment_fees) {
        const installmentPayload = { ...this.promocode };
        installmentPayload.amount = this.totalBreakdown.sub_total_amount;
        const installmentPromo$ =  this.breakdownService
          .checkPromoCode(installmentPayload)

        observables.push(installmentPromo$);
        indexMapping.installment = currentIndex++;
      }


      const promocodeResults = [];

      concat(...observables).subscribe(
        el => promocodeResults.push(el),
        err => {
          console.log('err: ', err);
          this.promocodeError = true;
          this.promocodeResult = null;
          this.payService.changePromocodeResult(null);
        },
        () => {
          console.log('completed!', promocodeResults);
          this.promocodeError = false;
          this.modalRef.hide();

          // Assign results dynamically based on indexMapping
          const result: PromocodeResult = {
            value:
              (promocodeResults[indexMapping.card] &&
                promocodeResults[indexMapping.card].value) ||
              0,
            promocode_id:
              (promocodeResults[indexMapping.card] &&
                promocodeResults[indexMapping.card].promocode_id) ||
              0,
            cash:
              indexMapping.cash !== undefined
                ? promocodeResults[indexMapping.cash].value
                : undefined,
            kiosk:
              indexMapping.kiosk !== undefined
                ? promocodeResults[indexMapping.kiosk].value
                : undefined,
            fawry:
              indexMapping.fawry !== undefined
                ? promocodeResults[indexMapping.fawry].value
                : undefined,
            valu:
              indexMapping.valu !== undefined
                ? promocodeResults[indexMapping.valu].value
                : undefined,
            upg:
              indexMapping.upg !== undefined
                ? promocodeResults[indexMapping.upg].value
                : undefined,
            installment:
              indexMapping.installment !== undefined
                ? promocodeResults[indexMapping.installment].value
                : undefined
          };

          this.promocodeResult = result;
          this.payService.changePromocodeResult(this.promocodeResult);
        }
      );
    }
  }
}
